import Head from 'next/head'
import Image from 'next/image'
import { Inter } from 'next/font/google'
import styles from '@/styles/Home.module.css'
import Footer from '@/components/Layout/Footer'
import GridContainer from '@/components/Layout/Grid/GridContainer'
import GridItem from '@/components/Layout/Grid/GridItem'

const inter = Inter({ subsets: ['latin'] })

export default function Home() {
  return (
    <>
      <Head>
        <title>Grupo SAVHAC</title>
        <meta property="og:title" content="Grupo SAVHAC"></meta>
        <meta property="twitter:title" content="Grupo SAVHAC"></meta>
        <meta property="description" content="SAVHAC - Industria de Alimentos" />
        <meta property="og:description" content="SAVHAC - Industria de Alimentos" />
        <meta property="twitter:description" content="SAVHAC - Industria de Alimentos"></meta>
        <meta property="og:url" content="https://www.gruposavhac.com.br"></meta>
        <meta property="og:image" content="https://www.gruposavhac.com.br/img/logo_gruposavhac.png"></meta>
        <meta property="twitter:image" content="https://www.gruposavhac.com.br/img/logo_gruposavhac.png"></meta>
        <meta name="facebook-domain-verification" content="h1jndlvighde7mupuwf2cmb5hnbhkp" />
      </Head>
      <GridContainer>
        <main className={`${styles.main} ${inter.className}`}>
          <GridContainer fluid rows>
            <GridItem>
              <Image
                className={styles.logo}
                src="/img/logo_gruposavhac.png"
                alt="Grupo SAVHAC"
                width={200}
                height={200}
                priority
              />
            </GridItem>
          </GridContainer>
          <GridContainer fluid rows>
            <GridItem className={`col ${styles.center} mt-4`}>
              <h2>Marcas</h2>
            </GridItem>
          </GridContainer>
          <GridContainer fluid rows>
            <GridItem className={`col ${styles.center}`}>
              <a
                href="https://confeitariasimonemello.com.br"
                className={`${styles.card}`}
                target="_blank"
              >
                <Image
                  className={styles.logo}
                  src="/img/logo_confeitariasimonemello.png"
                  alt="Confeitaria Simone Mello"
                  width={150}
                  height={150}
                  priority
                />
              </a>
            </GridItem>
            <GridItem className={`col ${styles.center}`}>
              <a
                href="https://meupotinho.com.br"
                className={`${styles.card}`}
                target="_blank"
              >
                <Image
                  className={styles.logo}
                  src="/img/logo_meupotinho.png"
                  alt="Meu Potinho"
                  width={150}
                  height={150}
                  priority
                />
              </a>
            </GridItem>
          </GridContainer>
        </main>
      </GridContainer>
      <Footer />
    </>
  )
}